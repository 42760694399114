<template>
    <div class="btn-group">
        <button class="save-btn" @click="$emit('save')">Сохранить</button>
        <button class="delete-btn" v-if="remove_active" @click="$emit('remove')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
        </button>
    </div>
</template>

<script>

export default{
    props: ['remove_active']
}

</script>


<style scoped>
.btn-group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 10px 0;
}


.save-btn{
    height: 50px;
    background: #1a9b36;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: bold;
    font-size: 16px;

    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.save-btn:active{
    background: #0e7a25;
    box-shadow: none;
}

.delete-btn{
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background: #e02d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.delete-btn:active{
    background: #bd1717;
    box-shadow: none;
}

.delete-btn svg{
    filter:invert();
    height: 30px;
    width: 30px;
    pointer-events: none;
}
</style>