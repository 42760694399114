import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import ItemPage from '../views/ItemPage.vue';
import ItemEditor from '../views/ItemEditor.vue';
import SampleEdit from '../views/SampleEdit.vue';
import NotePage from '../views/NotePage.vue';
import AuthPage from '@/views/AuthPage.vue';
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/item/:id',
    name: 'item',
    component: ItemPage
  },
  {
    path: '/edit/:id',
    name: 'edit_item',
    component: ItemEditor
  },
  {
    path: '/sample/:itemid/:id',
    name: "sample",
    component: SampleEdit
  },
  {
    path: '/note/:itemid/:id',
    name: "note",
    component: NotePage
  },
  {
    path: '/login',
    name: "login",
    component: AuthPage
  },
  {
    path: "/404",
    name: "404",
    component: NotFound
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
