<template>
    <div class="header-panel">
        <div class="header-btns">
            <BackBtn/>
            <button class="edit-btn" @click="$router.push(`/edit/${item.id}`)" v-if="item.id !== null">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
            </button>
        </div>
    </div>

    <LoaderBlock v-if="loading"/>
    <LoadError v-else-if="error" :text="error"/>

    <SliderImg :images="item.images" v-if="!loading && !error"/>
    <div class="content body-to-top" v-if="!loading && !error">
        <div class="card">
            <div class="card-title">
                <div class="manufacture">{{ item.manufacturer }}</div>
                <div class="product">{{ item.product }}</div>
            </div>
            <div class="card-body">
                <div class="info-item" v-if="item.compound">
                    <div class="i-title">Состав</div>
                    <div class="i-body">{{ item.compound }}</div>
                </div>
                <div class="info-item" v-if="item.length">
                    <div class="i-title">Метраж на 100г</div>
                    <div class="i-body">{{ item.length }}м</div>
                </div>
                <div class="info-item" v-if="item.color">
                    <div class="i-title">Цвет</div>
                    <div class="i-body">{{ item.color }}</div>
                </div>
                <div class="info-item" v-if="item.part">
                    <div class="i-title">Партия</div>
                    <div class="i-body">{{ item.part }}</div>
                </div>
                <div class="info-item">
                    <div class="i-title">Общий вес</div>
                    <div class="i-body">{{ item.weight }}г</div>
                </div>
            </div>
        </div>

        <div class="unit">
            <div class="unit-title">Образцы</div>
            <AddBtn @click="$router.push(`/sample/${item.id}/new`)"/>
        </div>

        <SampleItem v-for="sample, i in samples" :key="'sample'+i" :item="sample" :item_id="item.id"/>

        <div class="unit">
            <div class="unit-title">Заметки</div>
            <AddBtn @click="$router.push(`/note/${item.id}/new`)"/>
        </div>

        <NoteItem v-for="note, i in notes" :key="`note_${i}`" :note="note" :item_id="item.id"/>
    </div>
    
</template>

<script>

import BackBtn from '@/components/BackBtn.vue';
import AddBtn from '@/components/AddBtn.vue'
import SampleItem from '@/components/SampleItem.vue';
import NoteItem from '@/components/NoteItem.vue'
import SliderImg from '@/components/SliderImg.vue';
import LoaderBlock from '@/components/LoaderBlock.vue';
import LoadError from '@/components/LoadError.vue'

export default{
    components:{
        BackBtn,
        AddBtn,
        SampleItem,
        NoteItem,
        SliderImg,
        LoaderBlock,
        LoadError
    },
    data(){
        return {
            item: {
                id: null,
                images: [],
                manufacturer: "Ошибка загрузки",
                product: "",
                compound: "",
                color: "",
                part: "",
                length: 0,
                weight: 0,
            },
            samples: [],
            notes: [],
            loading: true,
            error: ''
        }
    },
    async mounted(){
        window.scrollTo(0,0)
        this.loadData()
    },
    methods: {
        async loadData(){
            this.loading = true;
            const result = await this.$request.get("/item", {id: this.$route.params.id});
            if (result.success) this.item = result.data
            else {
                this.error = result.error;
                this.loading = false;
                return
            }

            const samples = await this.$request.get("/samples", {'item-id': this.$route.params.id})
            if (samples.success) this.samples = samples.data

            const notes = await this.$request.get("/notes", {'item-id': this.$route.params.id})
            if (notes.success) this.notes = notes.data
            
            this.loading = false;
        }
    }

    
}

</script>

<style scoped>
.header-panel{
    z-index: 6;
    position: absolute;
    width: 100%;
}

.header-btns{
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0, 0.2) 40%, rgba(0,0,0,0.6) 100%);
    position: relative;
}

@media (min-width: 1020px) {
    .header-btns{
        width: 1020px;
        margin: 0 auto;
    }
}
.edit-btn{
    display: flex;
    width: 40px;
    height: 40px;
    background: none;
    position: absolute;
    top: 5px;
    right: 0px;
    border-radius: 40px;
    
    justify-content: center;
    align-items: center;
}

.edit-btn:active{
    background: rgba(255,255,255, 0.3);
}

.edit-btn svg{
    width: 20px;
    height: 20px;
    filter: invert();
    pointer-events: none;
}

.back-btn{
    top: 5px
}

.body-to-top{
    position: relative;
    top: -20px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: stretch;
}

.card{
    display: flex;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    padding: 10px;
    flex-direction: column;
    font-size: 12px;
}

.card-title{
    border-bottom: 1px solid rgba(0,0,0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    gap: 3px;
    padding: 10px 0;
    text-transform: uppercase;
}

.manufacture{
    text-align: center;
}

.product{
    color: rgba(0,0,0, .5);
    text-align: center;
}

.card-body{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info-item{
    font-size: 12px;

}

.i-title{
    color: rgba(0,0,0, 0.5)
}

.unit{
    font-size: 16px;
    font-weight: bold;
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
}

</style>