<template>	
	<LoaderBlock v-if="loading"/>
	<LoadError v-else-if="error" :text="error" :back="false"/>
	<div class="content" v-else>
		<div class="header">
			<div class="h-info">
				<div class="h-i-data">{{ stats.count }}</div>
				<div class="h-i-desc">{{ stat_count_name }}</div>
			</div>
			<div class="h-logo">
				<img src="/img/logo_min.png" alt="">
			</div>
			<div class="h-info">
				<div class="h-i-data">{{ (stats.weight/1000).toFixed(3).slice(0, -1) }}</div>
				<div class="h-i-desc">Общий вес</div>
			</div>
		</div>
		<div class="search" v-if="items.length" :style="search_focus ? {borderBottom: '1px solid #1A9B36'} : {}">
			<input class="search-input" id="search" type="text" placeholder="Поиск" @focusin="search_focus = true" @focusout="search_focus = false" v-model="search" autocomplete="off"/>
			<div class="search-done" v-if="search_focus" @click="removeSearchFocus()">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>keyboard-close-outline</title><path d="M12 23L16 19H8M4 3C2.9 3 2 3.9 2 5V15C2 16.1 2.9 17 4 17H20C21.1 17 22 16.1 22 15V5C22 3.9 21.1 3 20 3H4M4 5H20V15H4V5M5 6V8H7V6H5M8 6V8H10V6H8M11 6V8H13V6H11M14 6V8H16V6H14M17 6V8H19V6H17M5 9V11H7V9H5M8 9V11H10V9H8M11 9V11H13V9H11M14 9V11H16V9H14M17 9V11H19V9H17M8 12V14H16V12H8Z" /></svg>
			</div>
			<div class="search-clear" v-if="search" @click="clearSearch()"></div>
		</div>
		<div class="list" v-if="items.length">
			<WoolItem v-for="item in wools" :key="item.id" :item="item"/>
		</div>
		<div class="empty-list" :style="{height: empty_height}" v-else>
			<div class="empty-icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>emoticon-sad-outline</title><path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z" /></svg>
			</div>
			<div class="empty-text">У вас пока нет тут пряжи. <br>Добавьте ее скорее!</div>
			<router-link class="empty-new-item-btn" to="/edit/new">Добавить</router-link>
		</div>
	</div>

	<router-link v-if="!loading && items.length" class="new-item-btn" to="/edit/new"></router-link>
</template>

<script>
// @ is an alias to /src

import WoolItem from '@/components/WoolItem.vue';
import LoaderBlock from '@/components/LoaderBlock.vue';
import LoadError from '@/components/LoadError.vue';

export default {
	name: 'HomeView',
	components: {
		WoolItem,
		LoaderBlock,
		LoadError
	},
	data(){
		return {
			items: [],
			stats: {
				count: 0,
				weight: 0
			},
			loading: true,
			stat_count_name: "Артикулов",

			empty_height: '100%',
			error: '',

			search: "",
			search_focus: false
		}
	},
	async mounted(){
		this.empty_height = (window.innerHeight - 160)+ "px"
		const {data, success, error} = await this.$request.get("/items");
		this.loading = false
		if (success){
			this.items = data.items;
			this.stats = data.stats;

			this.stat_count_name = this.stats.count == 1 ? "Артикул" : this.stats.count >=2 && this.stats.count <= 4 ? "Артикула" : "Артикулов"
		}else this.error = error
		
	},
	methods: {
		removeSearchFocus(){
			const element = document.getElementById('search');
			if (element) element.blur();
		},
		clearSearch(){
			this.search = "";
			this.removeSearchFocus();
		}
	},
	computed:{
		wools(){
			let result
			if (!this.search) result = this.items;
			else{
				const search_arr = this.search.toLowerCase().split(" ").filter(val=> val);
				const filter = (item)=>{
					return search_arr.reduce((prev, str)=>{
						return (item.manufacturer.toLowerCase().includes(str) || item.product.toLowerCase().includes(str) || item.compound.toLowerCase().includes(str)) && prev
					}, true)
				}
				result = this.items.filter(filter)
			}

			return result.sort((a,b)=> b.created - a.created)
		}
	}
}
</script>

<style scoped>
.header{
	display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;
	background: #1A9B36;
	border-radius: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
	height: 80px;
    margin: 35px 0;
}

.h-logo{
	display: flex;
    background: #EAEAEA;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    justify-content: center;
    align-items: center;
}

.h-logo img, .h-logo svg{
	width: 125px;
	object-fit: cover;
}

.h-info{
	display: flex;
	flex-direction: column;
	color: #fff;
	width: 100px;
    gap: 5px;
	flex: 1;
	padding: 0 5px;
}

.h-info .h-i-data{
	font-size: 20px;
	font-weight: bold;
	display: flex;
    justify-content: center;
    border-bottom: 1px solid #fff;
}

.h-info .h-i-desc{
	font-size: 12px;
	display: flex;
    justify-content: center;
}

.search{
	display: flex;
	align-items: center;
    gap: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}

.search-input{
	border: none;
	background: none !important;
    padding: 10px;
    flex: 1;
    outline: none;
	font-size: 14px;
}

.search-clear, .search-done{
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
    align-items: center;
	position: relative;
}

.search-clear::before, .search-clear::after{
    content: " ";
    width: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.45);
    position: absolute;
    left: 5px;
    top: 15px;
}

.search-clear::before{
    transform: rotate(45deg);
}

.search-clear::after{
    transform: rotate(135deg);
}

.search-done svg{
	fill: rgba(0, 0, 0, 0.45);
	width: 23px;
	height: 23px;
}

.list{
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-item-btn{
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background: #1A9B36;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
	bottom: 20px;
	right: 20px;
}
.new-item-btn::after, .new-item-btn::before{
    width: 20px;
    border-bottom: 2px solid #fff;
    content: " ";
    display: block;
    position: absolute;
}

.new-item-btn::before{
    transform: rotate(90deg)
}

.new-item-btn:active{
    background: #0e8328;
}

.empty-new-item-btn{
    height: 50px;
    border-radius: 5px;
    background: #1A9B36;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    color: #fff;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.empty-new-item-btn:active{
    background: #0e8328;
}

.empty-list{
	display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	gap: 20px
}

.empty-items{
	flex-grow: 1;
}

.empty-text{
	text-align: center;
	color: rgba(0, 0, 0, 0.3)
}

.empty-icon{
	display: flex;
    justify-content: center;
}

.empty-icon svg{
	filter: opacity(0.2);
	width: 100px;
	height: 100px;
}

</style>

