<template>
    <router-link class="card note" :to="`/note/${item_id}/${note.id}`">
        {{ note.text }}
        <div class="overflow-gradient"></div>
    </router-link>
</template>

<script>

export default{
    props: ['note', 'item_id']
}

</script>

<style scoped>

.note{
    white-space: pre-wrap;
    height: 75px;
    overflow: hidden;
    position: relative;
}

.overflow-gradient{
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(0deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.2) 100%)
}

</style>