<template>
    <router-link :to="`/sample/${item_id}/${item.id}`" class="card sample">
        <div class="sample-info">
            <div class="knitt-name">{{ item.knitt.name }}</div>
            <div class="knitt-size">Размер: <span>{{ item.knitt.size }}</span></div>
        </div>
        <div class="vto">
            <div class="vto-box" v-if="item.before_vto.ranks">
                <div class="vto-ranks">{{ item.before_vto.ranks }}Р</div>
                <div class="vto-loops">{{ item.before_vto.loops }}П</div>
                <div class="vto-name">ДО</div>
            </div>
            <div class="vto-box" v-if="item.after_vto.ranks">
                <div class="vto-ranks">{{ item.after_vto.ranks }}Р</div>
                <div class="vto-loops">{{ item.after_vto.loops }}П</div>
                <div class="vto-name">ПОСЛЕ</div>
            </div>
        </div>
    </router-link>
</template>

<script>

export default{
    props: {
        item: {
            id: 0,
            knitt: {
                name: "ChiaoGoo bamboo",
                size: "6"
            },
            before_vto: {
                ranks: 28,
                loops: 30
            },
            after_vto:{
                ranks: 58,
                loops: 46
            }
        },
        item_id: {
            type: Number
        },
    }
}

</script>

<style scoped>

.sample{
    display: flex;
    flex-direction: row !important;
    gap: 15px;
}

.sample-info{
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.knitt-name{
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 5px 0;
}

.knitt-size{
    padding: 5px 0;
    color: rgba(0,0,0, 0.5)
}

.knitt-size span{
    color: #000
}

.vto{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
}

.vto-box{
    position: relative;
    font-size: 12px;
    width: 65px;
    height: 55px;
}

.vto-ranks, .vto-loops{
    width: 40px;
    border-bottom: 1px solid #000;
    text-align: center;
    position: absolute;
}

.vto-ranks{
    transform: rotate(270deg);
    top: 28px;
    left: -14px;
}

.vto-loops{
    top: 0px;
    left: 14px;
}

.vto-name{
    display: flex;
    width: 50px;
    height: 40px;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 14px;
    left: 12px;
}

</style>