<template>
    <div class="slider" ref="slider" @mousedown="startDragging" @touchstart="startDragging" @mouseup="endDragging" @touchend="endDragging">
        <div class="slides" v-if="images.length" :style="{ transform: `translateX(${position}px)` }">
            <img v-for="src, i in images" :key="i" :src="$ImageSrc(src)">
        </div>
        <div class="empty-slide" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>image-remove-outline</title><path d="M13 19C13 19.7 13.13 20.37 13.35 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3H19C20.11 3 21 3.9 21 5V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13M11.21 15.83L9.25 13.47L6.5 17H13.35C13.75 15.88 14.47 14.91 15.4 14.21L13.96 12.29L11.21 15.83M22.54 16.88L21.12 15.47L19 17.59L16.88 15.47L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88Z" /></svg>
        </div>
        <div class="btn-prev" @click="prev" v-if="buttons"></div>
        <div class="btn-next" @click="next" v-if="buttons"></div>
    </div>
</template>

<script>

export default{
    props: {
        images: {
            type: Array
        },
        buttons: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            position: 0,
            currentIndex: 1,
            width: 0,
            is_dragging: false,
            start_x: 0,
            prev_x: 0,
            is_scrolling: false
        }
    },
    mounted(){
        if (this.$refs.slider){
            this.width = this.$refs.slider.offsetWidth;
            if (this.images.length > 1)this.fakeMove()
        }

        window.addEventListener("resize", ()=>{
            if (this.$refs.slider){
                this.width = this.$refs.slider?.offsetWidth
                this.position = this.width * (this.currentIndex - 1) * -1
            } 
        })
    },
    methods:{
        next(element){
            const width = element.offsetWidth;
            this.currentIndex++;
            this.position = this.currentIndex > this.images.length ? 0 : (this.currentIndex - 1) * width * -1;
            if (!this.position) this.currentIndex = 1;
        },
        prev(element){
            const width = element.offsetWidth;
            this.currentIndex--;
            this.position = this.currentIndex <= 0 ? width * (this.images.length - 1) * -1 : (this.currentIndex - 1) * width * -1;
            if (this.currentIndex <= 0) this.currentIndex = this.images.length;
        },
        fakeMove(pos = 0){
            const old_pos = this.position;
            this.position = pos ? old_pos - pos : old_pos - Math.round(this.width/6);
            setTimeout(()=>{
                this.position = (this.currentIndex - 1) * this.width * -1
            }, 300)
            return pos
        },
        startDragging(event){
            if (this.images.length == 1) return;
            if (!this.width) this.width = event.target.offsetWidth;

            this.start_x = "changedTouches" in event ? event.changedTouches[0].clientX : event.clientX
        },
        endDragging(event){
            if (this.images.length == 1) return;
            const x_pos = "changedTouches" in event ? event.changedTouches[0].clientX : event.clientX;
            const width = event.target.offsetWidth

            const diff = this.start_x > x_pos ? this.start_x - x_pos : x_pos - this.start_x;
            if (diff >= width / 6){
                if (this.start_x > x_pos) this.next(event.target);
                else this.prev(event.target)
            }else this.fakeMove(this.start_x - x_pos)
        }
    }
}

</script>

<style scoped>

.slider{
    height: 220px;
    width: 100%;
    overflow: hidden;;
    position: relative;
    z-index: 0;
    scrollbar-width: none;
    background: #1a9b36;
}

@media (min-width: 1020px) {
  .slider{
    width: 1020px;
    margin: 0 auto;
  }
}
.empty-slide{
    display: flex;
    color: #fff;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 10px
}

.empty-slide svg{
    fill: #fff;
    width: 70px;
    height: 70px;
}

.slides{
    display: flex;
    height: 220px;
    transition: transform 0.3s ease;
    scrollbar-width: none;
}

.slider::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.slides img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex-shrink: 0;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.btn-prev, .btn-next{
    position: absolute;
    width: 40px;
    height: 40px;
    /* background: rgba(0,0,0, 0.2); */
    border-radius: 40px;
    top: 90px
}

.btn-prev{
    left: 5px;
}

.btn-next{
    right: 5px;
    transform: rotate(180deg);
}

.btn-prev::after, .btn-next::after, .btn-prev::before, .btn-next::before{
    content: " ";
    position: absolute;
    width: 15px;
    border-bottom: 1px solid #fff;
    left: 10px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.btn-prev::after, .btn-next::after{
    transform: rotate(135deg);
    top: 15px
}

.btn-prev::before, .btn-next::before{
    transform: rotate(45deg);
    top: 25px
}

</style>