<template>
	<ErrorDialog :text="error" @click="clearError"/>

	<LoaderBlock v-if="!loaded"/>
	<router-view v-else/>
</template>

<script>

import ErrorDialog from './components/ErrorDialog.vue';
import LoaderBlock from './components/LoaderBlock.vue';

export default{

	data(){
		return {
			error: "",
			loaded: false
		}
	},

	async mounted(){
		document.body.style.height = `${window.Telegram.WebApp.viewportHeight}px`;

		this.$ErrorHandler.setCallback((error)=>{
			this.error = error
		})

		if (/iPad|iPhone|iPod/.test(navigator.userAgent)) this.IOSScrollHandler();

		if (window.Telegram.WebApp.initData){
			window.Telegram.WebApp.setHeaderColor("#EAEAEA")
			window.Telegram.WebApp.expand()
			window.Telegram.WebApp.enableClosingConfirmation()

			window.Telegram.WebApp.onEvent('viewportChanged', ()=>{
				if (!window.Telegram.WebApp.isExpanded) window.Telegram.WebApp.expand()
				document.body.style.height = `${window.Telegram.WebApp.viewportHeight}px`
			})

			const auth = await this.$authorization();
			if (!auth.success) {
				this.error = "Ошибка авторизации! Перезапустите приложение"
				return;
			}
		}else {
			window.visualViewport.addEventListener("resize", ()=>{
				document.body.style.height = `${window.visualViewport.height}px`;
			})
		}

		const check = await this.$request.get("/check-auth");
		if (!check.success) await this.$router.push("/login");
		else if (this.$route.name == "login") await this.$router.push("/")
		this.loaded = true;
	},
	components: {
		ErrorDialog,
		LoaderBlock
	},
	methods:{
		clearError(){
			this.error = "";
		},
		IOSScrollHandler(){
			const app = document.getElementById("app");
			const focused_input = {
				top: 0,
				height: 0,
				is_focused: true
			}

			document.addEventListener('focusin', (event) => {
				if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
					const cors = event.target.getBoundingClientRect();

					focused_input.top = cors.top;
					focused_input.height = cors.height;
					focused_input.is_focused = true
				}
			});

			window.visualViewport.addEventListener("scroll", ()=>{
				if (focused_input.is_focused){
					const height = Math.min(window.visualViewport.height, window.Telegram.WebApp.viewportHeight)
					const view_diff = focused_input.top - height/2
					const top = Math.round(view_diff + (focused_input.height/2)) + app.scrollTop;

					app.scrollTo({top, behavior: "smooth"});
				}
				focused_input.is_focused = false;
				window.scrollTo({top: 0, behavior: "instant"})
			})
		}
	}
}

</script>

<style>
#app {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	overflow: scroll;
	scrollbar-width: none;
	height: 100%;
}

a{
	text-decoration: none;
	color: #000
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body{
	background: #EAEAEA;
	-webkit-text-size-adjust: none;
	overflow: hidden;
	-webkit-overflow-scrolling: none;
}

button{
	border: none;
	-webkit-tap-highlight-color: transparent;
}

button:focus{
	outline: none;
}

input[type="file"] {
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	z-index: -1;
}

.fixed-block{
	overflow: hidden;
	height: var(--tg-viewport-height);
}

.content{
	margin: 0 10px 0 10px;
	max-width: 1000px;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

@media (min-width: 1020px) {
	.content{
		margin: 0 auto;
	}
}
</style>
