<template>
    <div class="loader-content">
        <div class="loader"></div>
    </div>
</template>

<style scoped>

.loader-content{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.loader{
    display: flex;
    animation: rotation 1s linear infinite;
    width: 60px;
    height: 60px;
    border: 3px solid #1A9B36;
    border-bottom-color: transparent;
    border-radius: 50%;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
</style>