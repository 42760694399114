<template>
    <button class="back-btn" @click="$router.back()"><span></span></button>
</template>

<style scoped>

.back-btn{
    position: absolute;
    left: 0px;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    background: none;
}


.back-btn:active{
    background: rgba(0,0,0, 0.2);
}

.back-btn span::after{
    content: " ";
    border-bottom: 1px solid #fff;
    width: 15px;
    position: absolute;
    left: 13px;
}

.back-btn::before, .back-btn::after{
    content: " ";
    border-bottom: 1px solid #fff;
    width: 10px;
    position: absolute;
    left: 12px;
}

.back-btn::before{
    transform: rotate(45deg);
    top: 24px;
}

.back-btn::after{
    transform: rotate(135deg);
    top: 16px;
}

</style>