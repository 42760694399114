<template>
    <router-link :to="{path: `/item/${item.id}`}" class="list-item">
        <div class="item-img">
            <img v-if="item.img" :src="item.img ? $ImageSrc(item.img) : '/img/logo_min.png'" width="100">
            <div v-else class="no-img">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>image-remove-outline</title><path d="M13 19C13 19.7 13.13 20.37 13.35 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3H19C20.11 3 21 3.9 21 5V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13M11.21 15.83L9.25 13.47L6.5 17H13.35C13.75 15.88 14.47 14.91 15.4 14.21L13.96 12.29L11.21 15.83M22.54 16.88L21.12 15.47L19 17.59L16.88 15.47L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88Z" /></svg>
            </div>
        </div>
        <div class="item-info">
            <div class="item-title">{{ item.manufacturer }} {{ item.product }}</div>
            <div class="item-desc">
                <div class="item-desc-i">{{ item.compound }}</div>
                <div class="item-desc-i">Метраж: {{ item.length}}м/100г</div>
                <div class="item-desc-i">Общий вес: <b>{{ item.weight }}г</b></div>
            </div>
        </div>
    </router-link>
</template>

<script>

export default{
    props:{
        item: {}
    }
}

</script>

<style scoped>

.list-item{
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: stretch;
}
.list-item:active{
  background: #ebebeb;
  box-shadow: none;
}
.item-img{
  min-height: 100px;
  min-width: 100px;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 0 0 100px;
  position: relative;
}

.item-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.no-img{
  width: 100%;
  height: 100%;
  background: #1a9b36;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-img svg{
  width: 50px;
  height: 50px;
  fill: #fff;
}

.item-img-container{
  height: 100%;
}
.item-info{
  display: flex;
  padding: 5px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}
.item-title{
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
}
.item-desc{
  display: flex;
  color: rgba(0, 0, 0, 0.55);
  font-size: 12px;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}
.item-desc-i{
  overflow: hidden;
}

</style>