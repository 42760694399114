<template>
    <HeaderBlock :title="$route.params.id == 'new' ? 'Новая пряжа' : 'Редактирование'" :button="show_unfocus_btn"/>
    <LoaderBlock v-if="loading"/>
    <LoadError v-else-if="error" :text="error"/>
    <div class="content" :style="{marginTop: '80px'}" v-else>
        <div class="input-group">
            <InputItem :lable="'Производитель'" v-model="manufacturer" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Артикул'" v-model="product" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
        </div>

        <div class="input-group">
            <InputItem :lable="'Состав'" v-model="compound"  v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Метраж на 100г.'" :type="'number'" v-model="length"  v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Цвет'" v-model="color" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Партия'" v-model="part" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Общий вес, г.'" :type="'number'" v-model="weight" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
        </div>

        <div class="unit">
            <div class="unit-title">Изображения</div>
            <input type="file" id="add-images" accept="image/*" @change="onChangeImg">
            <label  class="add-btn" for="add-images"></label>
        </div>

        <div class="images" v-if="images.length">
            <div class="image" v-for="img, i in images" :key="i">
                <img :src="$ImageSrc(img.src)">
                <div class="image-info-box">
                    <button class="image-rm-btn" @click="removeImg(i)" v-if="img.loaded"></button>
                    <div class="image-loading" v-else-if="!img.error"></div>
                    <button class="image-rm-btn image-error" v-else @click="removeImg(i)"></button>
                </div>
            </div>
        </div>

        <div class="btn-group">
            <button class="save-btn" @click="save">Сохранить</button>
            <button class="delete-btn" v-if="$route.params.id !== 'new'" @click="remove()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
            </button>
        </div>
    </div>
    
</template>

<script>

import HeaderBlock from '@/components/HeaderBlock.vue';
import LoaderBlock from '@/components/LoaderBlock.vue';
import InputItem from '@/components/InputItem.vue';
import LoadError from '@/components/LoadError.vue';

export default {
    components: {
        HeaderBlock,
        LoaderBlock,
        InputItem,
        LoadError
    },
    data(){
        return{
            images: [],
            manufacturer: "",
            product: "",
            compound: "",
            length: 0,
            color: "",
            part: "",
            weight: 0,
            id: this.$route.params.id,

            server_images: [],
            removed_images: [],

            show_unfocus_btn: false,

            loading: true,
            error: ''
        }
    },
    async mounted(){
        window.scrollTo(0,0)
        if (this.id == 'new') return this.loading = false;

        const result = await this.$request.get("/item", {id: this.id});
        this.loading = false;

        if (result.success){
            this.images = result.data.images.map(val=> {return {src: val, loaded: true, new: false}}) || [];
            this.server_images = result.data.images
            this.manufacturer = result.data.manufacturer;
            this.product = result.data.product;
            this.compound = result.data.compound;
            this.length = result.data.length;
            this.color = result.data.color;
            this.part = result.data.part;
            this.weight = result.data.weight
        }else return this.error = result.error

    },
    methods:{
        async onChangeImg(event){
            const file = event.target.files[0];
            if (file && file.type.startsWith('image/')) {

                const images_index = this.images.length;
                this.images.push({
                    src: URL.createObjectURL(file),
                    loaded: false,
                    new: true
                })

                const formData = new FormData();
                formData.append('image', file);

                const result = await this.$request.img("/upload-img", formData)
                if (result.success){
                    const img = this.images[images_index];
                    img.src = result.file;
                    img.loaded = true;
                    img.new = true;
                }else {
                    const img = this.images[images_index];
                    img.error = result.error;
                    this.$ErrorHandler.error = result.error;
                }

            } else {
                this.selectedFile = null;
                this.previewImage = null;
                this.$ErrorHandler.error = "Пожалуйста, выберете изображение";
            }
        },
        async save(){
            const request = this.id == 'new' ? this.$request.post : this.$request.patch;

            if (this.id == 'new'){
                if (!this.manufacturer) return this.$ErrorHandler.error = `Производитель - обязательное поле`;
                if (!this.weight) return this.$ErrorHandler.error = `Общий вес должен быть больше 0`;
            }

            const data = {
                id: this.id,
                manufacturer: this.manufacturer,
                product: this.product,
                compound: this.compound,
                length: this.length,
                color: this.color,
                part: this.part,
                weight: this.weight,
                images: this.images.filter(val => val.loaded).map(val=> val.src)
            }
            
            const result = await request("/item", data)
            if (result.success){
                this.removed_images.forEach(src=>{
                    this.$request.delete("/image", {src})
                })

                if (this.id == "new") this.$router.push("/")
                else this.$router.back()
            }else this.$ErrorHandler.error = result.error;
        },
        async remove(){
            const result = await this.$request.delete("/item", {id: this.id});
            if (result.success) this.$router.push("/")
            else this.$ErrorHandler.error = result.error;
        },
        removeImg(index){
            const img = this.images.splice(index, 1)[0]
            if (img.new && img.loaded) this.removed_images.push(img.src)
        },
        showImgError(index, error){
            alert(error)
            this.images.splice(index, 1)
        }
    }
}

</script>

<style scoped>


.input-group{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 30px 0px 20px;
    gap: 15px;
}

.images{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0;
}

.image{
    position: relative;
}

.image img{
    width: 100px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    object-fit: cover;
}

.image-info-box{
    width: 100px;
    height: 100px;
    display: flex;
    position: absolute;
    border-radius: 5px;
    top: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
}

.image-rm-btn{
    width: 50px;
    height: 50px;
    background: rgba(0,0,0, 0.5);
    border-radius: 50%;
    position: relative;
}

.image-rm-btn::before, .image-rm-btn::after{
    content: " ";
    width: 20px;
    border-bottom: 1px solid #fff;
    position: absolute;
    left: 15px;
    top: 25px;
}

.image-rm-btn::before{
    transform: rotate(45deg);
}

.image-rm-btn::after{
    transform: rotate(135deg);
}

.image-rm-btn:active{
    background: rgba(0,0,0, 0.7);
}

.image-loading{
    width: 50px;
    height: 50px;
    background: rgba(0,0,0, 0.5);
    border-radius: 50%;
    position: relative;
}

.image-loading::before{
    content: " ";
    animation: rotation_min 1s linear infinite;
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    position: absolute;
    left: 12px;
    top: 12px;
}

.image-error{
    background: rgba(255, 0, 0, 0.3);
}

.image-error:active{
    background: rgba(255, 0, 0, 0.5);
}

.btn-group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 10px 0;
}


.save-btn{
    height: 50px;
    background: #1a9b36;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: bold;
    font-size: 16px;

    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.save-btn:active{
    background: #0e7a25;
    box-shadow: none;
}

.delete-btn{
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background: #e02d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.delete-btn:active{
    background: #bd1717;
    box-shadow: none;
}

.delete-btn svg{
    filter:invert();
    height: 30px;
    width: 30px;
    pointer-events: none;
}

.unit{
    font-size: 16px;
    font-weight: bold;
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
}

.add-btn{
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background: #1A9B36;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}
.add-btn::after, .add-btn::before{
    width: 10px;
    border-bottom: 2px solid #fff;
    content: " ";
    display: block;
    position: absolute;
}

.add-btn::before{
    transform: rotate(90deg)
}

.add-btn:active{
    background: #0e8328;
}


@keyframes rotation_min {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
</style>