<template>
    <div class="add-btn"></div>
</template>

<style scoped>
.add-btn{
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background: #1A9B36;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}
.add-btn::after, .add-btn::before{
    width: 10px;
    border-bottom: 2px solid #fff;
    content: " ";
    display: block;
    position: absolute;
}

.add-btn::before{
    transform: rotate(90deg)
}

.add-btn:active{
    background: #0e8328;
}
</style>