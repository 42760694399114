<template>
    <HeaderBlock :title="$route.params.id == 'new' ? 'Новая заметка' : 'Редактирование заметки'" :button="button_active"/>

    <LoaderBlock v-if="!loaded"/>

    <LoadError :text="error"/>

    <div class="note-block" v-if="loaded && !error">
        <textarea name="" id="" cols="30" rows="10" class="note-body" :style="{height: height}" v-model="text" @focus="button_active = true" @focusout="button_active = false"></textarea>
    </div>
    <div class="btns-block" v-if="loaded && !error">
        <SaveRemoveBtns :remove_active="$route.params.id !== 'new'" @save="save" @remove="remove"/>
    </div>
</template>

<script>

import HeaderBlock from '@/components/HeaderBlock.vue';
import SaveRemoveBtns from '@/components/SaveRemoveBtns';
import LoadError from '@/components/LoadError.vue';
import LoaderBlock from '@/components/LoaderBlock.vue';

export default{
    components:{
        HeaderBlock,
        SaveRemoveBtns,
        LoadError,
        LoaderBlock
    },
    data(){
        return{
            height: "80vh",
            text: "",
            button_active: false,
            loaded: false,
            error: ""
        }
    },
    async mounted(){
        this.height = (window.innerHeight - 120) + "px";
        window.addEventListener("resize", ()=>{
            this.height = (window.innerHeight - 120) + "px";
        })

        if (this.$route.params.id == "new") return this.loaded = true;

        const result = await this.$request.get("/note", {id: this.$route.params.id, 'item-id': this.$route.params.itemid})
        this.loaded = true
        if (result.success){
            this.text = result.text
        }else this.error = result.error
    },
    methods: {
        async save(){
            const options = {
                id: this.$route.params.id,
                itemID: this.$route.params.itemid,
                text: this.text
            }

            const request = options.id == "new" ? this.$request.post : this.$request.patch;

            const result = await request("/note", options);
            if (result.success) this.$router.back()
            else this.$ErrorHandler.error = result.error
        },
        async remove(){
            const result = await this.$request.delete("/note", {id: this.$route.params.id, itemID: this.$route.params.itemid});
            if (result.success) this.$router.back()
            else this.$ErrorHandler.error = result.error
        }
    }
}

</script>

<style>

.note-block{
    max-width: 1020px;
    width: 100%;
    margin: 50px auto 0;
}

.note-body{
    width: 100%;
    font-family: "Roboto", sans-serif;
    padding: 10px;
    border: none;
    outline: none !important;
    font-size: 16px;
    resize: none;
}

.btns-block{
    width: 100%;
    background: #fff;
    padding: 0 10px;
    position: fixed;
    height: 70px;
    bottom: 0px;
    left: 0px;
}

</style>