<template>
    <!-- <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-login="ost56testbot" data-size="large" data-radius="5" data-onauth="onTelegramAuth(user)" data-request-access="write"></script>
    <script type="text/javascript">
    function onTelegramAuth(user) {
        alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
    }
    </script> -->

    <div class="login-block">
        <div class="logo">
            <img src="/img/logo_min.png" alt="">
        </div>
        <div class="login-text">Чтобы воспользоваться сайтом - авторизуйтесь через Telegram</div>
        <div id="tlogin"></div>
    </div>
</template>


<script>

export default{
    mounted(){
        const script = document.createElement('script')
        script.async = true;
        script.src = "https://telegram.org/js/telegram-widget.js?22"
        script.setAttribute('data-telegram-login',"WoolHamsterBot")
        script.setAttribute('data-size',"large")
        script.setAttribute('data-radius',"5")
        window.onTelegramAuth = this.login;
        script.setAttribute("data-onauth", "window.onTelegramAuth(user)");
        script.setAttribute('data-request-access',"write")
        document.getElementById("tlogin").append(script)
    },
    methods:{
        async login(user){
            const {success} = await this.$authorization(user);
            if (success) this.$router.back()
        }
    }
}

</script>

<style scoped>

.login-block{
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.login-text{
    text-align: center;
}

.logo{
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

</style>