<template>
    <div class="header" id="header">
        <div class="c-header">
            <BackBtn/>
            <div class="header-title">{{ title }}</div>
            <button class="right-btn" v-if="button" @click="$emit('button')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>keyboard-close-outline</title><path d="M12 23L16 19H8M4 3C2.9 3 2 3.9 2 5V15C2 16.1 2.9 17 4 17H20C21.1 17 22 16.1 22 15V5C22 3.9 21.1 3 20 3H4M4 5H20V15H4V5M5 6V8H7V6H5M8 6V8H10V6H8M11 6V8H13V6H11M14 6V8H16V6H14M17 6V8H19V6H17M5 9V11H7V9H5M8 9V11H10V9H8M11 9V11H13V9H11M14 9V11H16V9H14M17 9V11H19V9H17M8 12V14H16V12H8Z" /></svg>
            </button>
        </div>
    </div>
</template>

<script>
import BackBtn from '@/components/BackBtn.vue'


export default{
    props: {
        title: {
            type: String
        },
        button: {
            type: Boolean,
            default: false
        }
    },
    components: {
        BackBtn
    }
}

</script>

<style scoped>

.header{
    height: 50px;
    background: #1A9B36;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    transition: top 0.15s ease;
}

.c-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1020px;
    position: relative;
}

.header-title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.right-btn{
    position: absolute;
    right: 0px;
    background: none;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-btn svg{
    height: 25px;
    width: 25px;
    fill: #fff;
}
</style>