<template>
    <HeaderBlock :title="$route.params.id == 'new' ? 'Новый образец' : 'Редактирование образца'" :button="show_unfocus_btn"/>

    <LoaderBlock v-if="!loaded"/>
    <LoadError v-else-if="error" :text="error"/>

    <div class="content" :style="{marginTop: '80px'}" v-else>
        <div class="input-group">
            <InputItem :lable="'Спицы'" v-model="knitt.name" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Размер спиц'" v-model="knitt.size" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
        </div>

        <div class="input-group">
            <div class="ig-desc">Укажите плотность вязания для образца 10x10 см</div>
            <div class="ig-title">До ВТО</div>
            <InputItem :lable="'Петель'" v-model="before_vto.loops" :type="'number'" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Рядов'" v-model="before_vto.ranks" :type="'number'" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
        </div>

        <div class="input-group">
            <div class="ig-title">После ВТО</div>
            <InputItem :lable="'Петель'" v-model="after_vto.loops" :type="'number'" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
            <InputItem :lable="'Рядов'" v-model="after_vto.ranks" :type="'number'" v-on:focus="show_unfocus_btn = true" v-on:unfocus="show_unfocus_btn = false"/>
        </div>

        <div class="btn-group">
            <button class="save-btn" @click="save()">Сохранить</button>
            <button class="delete-btn" v-if="$route.params.id !== 'new'" @click="remove()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
            </button>
        </div>
    </div>
</template>

<script>

import HeaderBlock from '@/components/HeaderBlock.vue';
import LoaderBlock from '@/components/LoaderBlock.vue';
import InputItem from '@/components/InputItem.vue';
import LoadError from '@/components/LoadError.vue';

export default {
    components: {
        HeaderBlock,
        LoaderBlock,
        InputItem,
        LoadError
    },
    data(){
        return{
            knitt: {
                name: "",
                size: ""
            },
            before_vto: {
                ranks: 0,
                loops: 0
            },
            after_vto:{
                ranks: 0,
                loops: 0
            },

            show_unfocus_btn: false,

            loaded: false,
            error: ''
        }
    },
    async mounted(){
        window.scrollTo(0,0)
        if (this.$route.params.id == "new") return this.loaded = true;

        const result = await this.$request.get("/sample", {id: this.$route.params.id, 'item-id': this.$route.params.itemid})
        if (result.success){
            this.knitt.name = result.data.knitt.name;
            this.knitt.size = result.data.knitt.size

            this.before_vto.ranks = result.data.before_vto.ranks;
            this.before_vto.loops = result.data.before_vto.loops;

            this.after_vto.ranks = result.data.after_vto.ranks;
            this.after_vto.loops = result.data.after_vto.loops;
        }else this.error = result.error
        
        this.loaded = true
    },
    methods:{
        async save(){
            const options = {
                id: this.$route.params.id,
                itemID: this.$route.params.itemid,
                knitt: {
                    name: this.knitt.name,
                    size: this.knitt.size
                },
                before_vto: {
                    ranks: this.before_vto.ranks,
                    loops: this.before_vto.loops
                },
                after_vto:{
                    ranks: this.after_vto.ranks,
                    loops: this.after_vto.loops
                }
            }

            const request = options.id == "new" ? this.$request.post : this.$request.patch;

            const result = await request("/sample", options);
            if (result.success) this.$router.back()
            else this.$ErrorHandler.error = result.error
        },
        async remove(){
            const result = await this.$request.delete("/sample", {id: this.$route.params.id, itemID: this.$route.params.itemid});
            if (result.success) this.$router.back()
            else this.$ErrorHandler.error = result.error
        }
    }
}

</script>

<style scoped>

.input-group{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 30px 0px 20px;
    gap: 15px;
}

.input-group .ig-title{
    display: flex;
    font-size: 16px;
    font-weight: bold;
}

.input-group .ig-desc{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
}


.input{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.input-lable{
    position: absolute;
    font-size: 12px;
    color: #a3a3a3;
    top: -7px;
    left: 12px;
    padding: 0 5px;
    background: #eaeaea;
}

.input input{
    background: none;
    border: 1px solid #a3a3a3;
    border-radius: 5px;
    padding: 16px;
    outline: none;
    font-size: 14px;
}

.input input:focus{
    border: 1px solid #1A9B36;
}

.input input:focus + .input-lable{
    color: #1A9B36;
}

.images{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0;
}

.images img{
    width: 100px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    object-fit: cover;
}

.btn-group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 10px 0;
}


.save-btn{
    height: 50px;
    background: #1a9b36;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: bold;
    font-size: 16px;

    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.save-btn:active{
    background: #0e7a25;
    box-shadow: none;
}

.delete-btn{
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background: #e02d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

.delete-btn:active{
    background: #bd1717;
    box-shadow: none;
}

.delete-btn svg{
    filter:invert();
    height: 30px;
    width: 30px;
    pointer-events: none;
}

.unit{
    font-size: 16px;
    font-weight: bold;
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
}



</style>