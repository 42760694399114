<script setup>

import { defineModel, ref, defineProps, defineEmits} from 'vue';

const model = defineModel();

defineEmits(['focus', 'unfocus'])

const props = defineProps({
    type: {
        type: String,
        default: "text"
    },
    lable: String
})

const type = ref(props.type)
const lable = ref(props.lable)
const input = ref()

const keys = (event) => {
    if (event.key === 'Enter') input.value.blur();
};
</script>

<template>
    <div class="input">
        <input ref="input" :type="type" v-model="model" @focusin="$emit('focus')" @focusout="$emit('unfocus')" @keypress="keys">
        <div class="input-lable">{{ lable }}</div>
    </div>
</template>

<style scoped>
.input{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.input-lable{
    position: absolute;
    font-size: 12px;
    color: #a3a3a3;
    top: -7px;
    left: 12px;
    padding: 0 5px;
    background: #eaeaea;
}

.input input{
    background: none;
    border: 1px solid #a3a3a3;
    border-radius: 5px;
    padding: 16px;
    outline: none;
    font-size: 14px;
}

.input input:focus{
    border: 1px solid #1A9B36;
}

.input input:focus + .input-lable{
    color: #1A9B36;
}

</style>