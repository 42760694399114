<template>
    <div class="loader-content" v-if="text">
        <div class="error-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close-circle-outline</title><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" /></svg>
        </div>
        <div class="error-text">{{ text }}</div>
        <div class="error-btns">
            <div class="error-btn" v-if="back" @click="$router.back()">Назад</div>
            <div class="error-btn" @click="$router.go(0)">Обновить</div>
        </div>
    </div>
</template>

<script>

export default{
    props: {
        text: {
            type: String,
            default: ""
        },
        back: {
            type: Boolean,
            default: true
        }
    }
}

</script>

<style scoped>

.loader-content{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
}

.error-icon{
    width: 100px;
    height: 100px;
    fill: #ababab;
}

.error-text{
    padding: 0 20px;
    text-align: center;
}

.error-btns{
    display: flex;
    gap: 15px;
}

.error-btn{
    display: flex;
    align-items: center;
    background:#1A9B36;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}

</style>