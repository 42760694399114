<template>
    <div class="background-dialog" v-if="text">
        <div class="modal-window">
            <div class="error-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close-circle-outline</title><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" /></svg>
            </div>
            <div class="error-text">
                {{ text }}
            </div>
        </div>
    </div>
</template>

<script>

export default{
    props: {
        text: {
            type: String,
            default: ""
        }
    }
}

</script>


<style scoped>
.background-dialog{
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    z-index: 5;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
}

.modal-window{
    display: flex;
    width: 250px;
    background: #fff;
    margin: 0 auto;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    flex-direction: column;
    align-items: center;
}

.error-icon{
    width: 80px;
    height: 80px;
    fill: #df4646;
}

.error-text{
    text-align: center;
    white-space: pre-wrap;
}

</style>