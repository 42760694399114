<template>

    <div class="conteiner">
        <div class="title">404</div>
        <div class="desc">Страница не найдена</div>
        <div class="error-btns">
            <div class="error-btn" @click="$router.back()">Назад</div>
            <div class="error-btn" @click="$router.push('/')">На главную</div>
        </div>
    </div>

</template>

<style scoped>

.conteiner{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.title{
    font-size: 50px;
}

.error-btns{
    display: flex;
    gap: 15px;
}

.error-btn{
    display: flex;
    align-items: center;
    background:#1A9B36;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}

</style>