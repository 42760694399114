
const DEV = false;

//if (DEV) localStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDc2MzczOTUzLCJmaXJzdF9uYW1lIjoi0JjQu9GM0Y8iLCJwaG90byI6Imh0dHBzOi8vdC5tZS9pL3VzZXJwaWMvMzIwL1lndmFDalllc19yOU5PRFl4MF9nSVVQcGdwUWhPWndtVW4xc29RbEN0T1UuanBnIiwiYXV0aF90eXBlIjoid2ViIiwiaWF0IjoxNzE5ODQyNTM1LCJleHAiOjE3MTk5Mjg5MzUsImhhc2giOiJpWmtjTHI0M3FROVZlNHkifQ.UtEvI9VFRVvjBs-Huff8Olmnu5KxdUjkgpJ9XqY3Qsc")


export default {
    install(app) {
        app.config.globalProperties.$authorization = Authorization

        app.config.globalProperties.$request = {
            get: function (path = "/", data = null){
                return HttpRequest(path, data, "GET")
            },
            post: function(path, data){
                return HttpRequest(path, data, "POST")
            },
            patch: function (path = "/", data = null){
                return HttpRequest(path, data, "PATCH")
            },
            delete: function (path = "/", data = null){
                return HttpRequest(path, data, "DELETE")
            },
            img: function(path, data){
                return HttpRequest(path, data, "POST", "multipart/form-data")
            }
        }

        app.config.globalProperties.$ErrorHandler = new ErrorHandler();
        app.config.globalProperties.$ImageSrc = (src)=>{
            if (!DEV || !src.includes("/images/")) return src;
            return `https://hamster.sweeterburg.ru${src}`
        }
    }
}

async function Authorization(user = null){
    let result;
    if (!user) result = await this.$request.post("/web-app-auth", window.Telegram.WebApp.initData);
    else result = await this.$request.post("/telegram-auth", user);

    if (result.success) localStorage.setItem("token", result.token);
    return result
}

async function HttpRequest(path = "/", data = null, method = "GET", type = "application/json; charset=utf-8"){
    let url = (DEV ? "https://hamster.sweeterburg.ru/api" : "/api") + path;

    const options = {
        method,
        headers: {
            'Authorization': localStorage.getItem('token') || "",
            //"Content-Type": type
        },
        timeout: 30000
    }

    if (method !== "GET" && data){
        if (type == "application/json; charset=utf-8") options.body = JSON.stringify(data);
        else options.body = data
    }else if (data){
        const params = new URLSearchParams();
        for (let key in data){
            params.set(key, data[key])
        }
        url += "?" + params.toString()
    }

    try{
        return await fetch(url, options).then(res=> res.json())
    }catch(_error){
        let error = _error.message;
        if (_error.message == "Failed to fetch" || _error.message == "Load failed") error = "Ошибка при загрузке данных. Проверьте подключение к интернету и повторите попытку"
        return {success: false, error}
    }
}

class ErrorHandler{
    __callback = ()=>{};
    setCallback(callback = ()=>{}){
        this.__callback = callback;
    }

    set error(val){
        this.__callback(val)
    }
}